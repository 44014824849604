body {
  font-weight: 300;
}

.fa-btn {
  margin-right: 6px;
}

html {
  overflow-y: scroll;
}

.bg-alt {
  background-color: #ebebeb;
  padding-top: 30px;
  padding-bottom: 30px;
}

.site-footer {
  padding-top: 20px;

  .footer-text {
    padding-top: 20px;
    border-top: 1px solid #d2d2d2;

  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.about-lock {
  margin: 40px 0 0 0;
  max-width: 150px;
}

.about-campaigns {
  max-width: 630px;
  margin: 20px auto;
}

.text-primary {
  color: $brand-primary;
}

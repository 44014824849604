.border-none {
  border: 0;
}

.p-t-lg {
  padding-top: 20px;
}

.p-r-lg {
  padding-right: 20px;
}

.p-b-lg {
  padding-bottom: 20px;
}

.p-l-lg {
  padding-left: 20px;
}

.p-md {
  padding: 15px;
}

.p-t-md {
  padding-top: 15px;
}

.p-r-md {
  padding-right: 15px;
}

.p-b-md {
  padding-bottom: 15px;
}

.p-l-md {
  padding-left: 15px;
}

.p-t-xs {
  padding-top: 5px;
}

.p-r-xs {
  padding-right: 5px;
}

.p-b-xs {
  padding-bottom: 5px;
}

.p-l-xs {
  padding-left: 5px;
}

.p-none {
  padding: 0;
}

.p-t-none {
  padding-top: 0;
}

.p-r-none {
  padding-right: 0;
}

.p-b-none {
  padding-bottom: 0;
}

.p-l-none {
  padding-left: 0;
}

.m-t-lg {
  margin-top: 20px;
}

.m-r-lg {
  margin-right: 20px;
}

.m-b-lg {
  margin-bottom: 20px;
}

.m-l-lg {
  margin-left: 20px;
}

.m-t-md {
  margin-top: 15px;
}

.m-r-md {
  margin-right: 15px;
}

.m-b-md {
  margin-bottom: 15px;
}

.m-l-md {
  margin-left: 15px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-l-xs {
  margin-left: 5px;
}

.m-none {
  margin: 0;
}

.m-t-none {
  margin-top: 0;
}

.m-r-none {
  margin-right: 0;
}

.m-b-none {
  margin-bottom: 0;
}

.m-l-none {
  margin-left: 0;
}

.alert {
  font-weight: 300;
}

// Basic Buttons
.btn {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  transition: background-color 0.2s;
}

.btn-primary, .btn-info, .btn-success, .btn-warning, .btn-danger {
  border: 0;
}

.btn-default:hover {
  background-color: white;
}

// File Upload Button
.btn-upload {
  overflow: hidden;
  position: relative;

  input[type="file"] {
    cursor: pointer;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

// Other Button Utilities
.btn-table-align {
  padding-top: $padding-base-vertical + 1px;
  padding-bottom: $padding-base-vertical + 1px;
}

.spark-screen {
  form h2 {
    background-color: $panel-default-heading-bg;
    border-radius: $border-radius-base;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 15px;
    padding: 12px;
  }
}

.control-label {
  font-weight: 300;
}

.radio label, .checkbox label {
  font-weight: 300;
}

.navbar-inverse .navbar-brand {
  color: $navbar-inverse-link-color;
  font-weight: 400;
}

.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: $navbar-inverse-link-color;
}

.navbar-nav > li > a {
  cursor: pointer;
  font-weight: 400;
}

.navbar-nav > li > a,
.navbar-brand,
.hamburger {
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar-icon {
  padding-top: 3px;
  position: relative;

  .activity-indicator {
    width: 13px;
    height: 13px;
    border-radius: 10px;
    background: $brand-danger;
    border: 2px solid #fff;
    border-radius: 10px;
    position: absolute;
    right: -1px;
    transition: all .15s;
    transform: scale(.85);
  }

  .icon {
    font-size: 21px;
  }
}

.has-activity-indicator:hover .activity-indicator {
  transform: scale(1.1);
}

.navbar .dropdown-header {
  font-weight: bold;
  margin-bottom: 5px;
}

.navbar .dropdown-menu > li > a {
  font-weight: 300;

  i {
    color: lighten($text-color, 25%);
  }

  i.text-success {
    color: $brand-success;
  }
}

.hamburger {
  float: right;
}

.with-navbar {
  padding-top: 90px;
}

.panel {
  overflow: hidden;
}

.panel-heading {
  font-size: 15px;
  font-weight: 400;
}

.panel-body {
  font-weight: 300;
}

.panel-flush {
  .panel-body, .panel-header {
    padding: 0;
  }
}

.table-borderless {
  > thead > tr > th {
    border-bottom: 0;
  }

  > tbody > tr > td {
    border-top: 0;
  }
}
